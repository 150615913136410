import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import {
  HiCheckCircle,
  SiFacebook,
  SiLinkedin,
  SiTwitter,
} from "react-icons/all";

import useTranslations from "../components/useTranslations";
import reactStringReplace from "react-string-replace";
import HelmetHeader from "../components/helmetHeader";
import GetStarted from "../components/getStarted";

const RecipeArticle = ({ data: { mdx }, location }) => {
  const { lang, recipeTemplate } = useTranslations();
  return (
    <>
      <HelmetHeader
        location={location}
        lang={lang}
        title={mdx.frontmatter.title}
        metas={[
          {
            name: "description",
            property: "og:description",
            content: mdx.frontmatter.metaDescription,
          },
          {
            name: "site_name",
            property: "og:site_name",
            content: "Addingwell",
          },
          {
            name: "url",
            property: "og:url",
            content: `${process.env.GATSBY_WEBSITE_URL}/${mdx.parent.relativeDirectory}`,
          },
          { name: "type", property: "og:type", content: "article" },
          {
            name: "title",
            property: "og:title",
            content: mdx.frontmatter.metaTitle,
          },
          {
            name: "image",
            property: "og:image",
            content: mdx.frontmatter.metaImage,
          },
          { name: "twitter:title", content: mdx.frontmatter.metaTitle },
          {
            name: "twitter:description",
            content: mdx.frontmatter.metaDescription,
          },
          { name: "twitter:image", content: mdx.frontmatter.metaImage },
          { name: "twitter:card", content: "summary_large_image" },
        ]}
      />
      <div className="bg-gradient-to-r from-teal-500 to-cyan-600">
        <div className="max-w-6xl mx-auto flex flex-col px-4 sm:px-8 lg:px-16 py-16 sm:pt-16 sm:pb-24">
          <h1 className="mt-1 text-2xl font-extrabold text-white sm:text-3xl sm:tracking-tight lg:text-4xl">
            {mdx.frontmatter.title}
          </h1>
          <div className="flex flex-col lg:flex-row max-w-5xl gap-12 mt-16">
            <div className="flex flex-col flex-1 flex-grow flex-shrink">
              <p className="text-xl text-justify text-white">
                {reactStringReplace(
                  mdx.frontmatter.description,
                  "<br/>",
                  () => (
                    <br />
                  )
                )}
              </p>
            </div>
            <div className="w-full lg:w-80 flex flex-col lg:border-l lg:border-cyan-600 lg:pl-12">
              <div className="flex flex-col mb-5">
                {mdx.frontmatter?.authors?.map((author, index) => {
                  return (
                    <div key={index} className="flex gap-3 items-center mb-5">
                      <img
                        src={author.icons.template}
                        alt={author.alt}
                        className="h-8 w-8"
                      />
                      <span className="text-white text-md">
                        {reactStringReplace(
                          recipeTemplate.madeBy,
                          "{{authorName}}",
                          () => (
                            <b>{author.name}</b>
                          )
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
              <span className="text-lg font-medium text-white mb-3">
                {recipeTemplate.prerequisites}
              </span>
              <ul>
                {mdx.frontmatter?.prerequisites?.map((prerequisite, index) => {
                  return (
                    <li key={index} className="mb-2">
                      <div className="flex gap-2 items-center">
                        <HiCheckCircle className="h-5 w-5 text-cyan-700 flex-shrink-0" />
                        <a
                          href={prerequisite.href}
                          target="_blank"
                          className="font-normal flex-grow flex-shrink text-sm text-gray-200 hover:underline"
                        >
                          {prerequisite.label}
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white relative max-w-6xl mx-auto">
        <div className="mx-auto px-4 sm:px-8 lg:px-16 flex flex-col lg:grid gap-10 lg:grid-cols-3">
          <div className="col-start-1 col-end-1">
            <div className="lg:sticky lg:top-32 mt-10 bg-gray-100">
              {mdx.tableOfContents?.items?.length > 0 && (
                <div className="border-b border-gray-200 py-5 px-5">
                  <h3 className="text-sm font-medium text-gray-900">
                    {recipeTemplate.onThisPage}
                  </h3>
                  <div className="mt-4 prose prose-sm text-cyan-600">
                    <ul>
                      {mdx.tableOfContents?.items?.map((article, index) => {
                        return (
                          <li key={index}>
                            <a href={article.url}>
                              <span className="font-normal no-underline">
                                {article.title}
                              </span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}

              <div className="py-5 px-5">
                <h3 className="text-sm font-medium text-gray-900">
                  {recipeTemplate.share}
                </h3>
                <div className="mt-4">
                  <ul className="flex items-center space-x-6 mt-4">
                    <li>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.GATSBY_WEBSITE_URL}/${mdx.parent.relativeDirectory}`}
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">
                          {recipeTemplate.shareOnFacebook}
                        </span>
                        <SiFacebook className="w-5 h-5" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://twitter.com/share?url=${process.env.GATSBY_WEBSITE_URL}/${mdx.parent.relativeDirectory}&text=${mdx.frontmatter.title}`}
                        target="_blank"
                        className="flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500"
                        rel="noreferrer"
                      >
                        <span className="sr-only">
                          {recipeTemplate.shareOnTwitter}
                        </span>
                        <SiTwitter className="w-5 h-5" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${process.env.GATSBY_WEBSITE_URL}/${mdx.parent.relativeDirectory}&title=${mdx.frontmatter.title}&summary=${mdx.frontmatter.description}&source=Addingwell`}
                        target="_blank"
                        className="flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500"
                        rel="noreferrer"
                      >
                        <span className="sr-only">
                          {recipeTemplate.shareOnLinkedin}
                        </span>
                        <SiLinkedin className="w-5 h-5" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <article className="article text-justify my-8 col-start-2 col-end-4">
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </article>
        </div>
      </div>
      <GetStarted />
    </>
  );
};

export default RecipeArticle;

export const query = graphql`
  query RecipeArticle($locale: String!, $title: String!) {
    mdx(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
        prerequisites {
          label
          href
        }
        authors {
          name
          alt
          icons {
            template
          }
        }
        intro
        metaTitle
        metaDescription
        metaImage
        authorName
        authorPosition
        authorPicture
      }
      parent {
        ... on File {
          relativeDirectory
        }
      }
      tableOfContents(maxDepth: 2)
      body
    }
  }
`;
